import React from 'react';
import {  graphql} from 'gatsby';
import Layout from '../components/layout';
import LetsWorkTogether from '../components/letsWorkTogether';
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet';

const aboutTemplate = ({
	data: {file, allWordpressPost},
	pageContext: {
		catId,
		catName,
		catSlug,
		categories,
		humanPageNumber,
		numberOfPages,
	},
}) => 
   <Layout>
   
   <Helmet>
   <style type="text/css">
   {`
         #bg-stretch1596537568899212 {
            background-image: url('/office.jpg');
         }
        `} 
      </style>
   </Helmet>
	<SEO description="We are group of digital specialists who have been partnering with organisations across many industries for the past 17 years." title = "About us" />
	<nav aria-label="breadcrumb" className="breadcrumbs-section border-top border-bottom mb-0">
		<div className="container">
			<ol className="breadcrumb mb-0 py-2 px-0">
				<li className="breadcrumb-item"><a href="/">Home</a></li>
				<li className="breadcrumb-item active">{catName}</li>
			</ol>
		</div>
	</nav>
	<section className="intro-section pt-10 pb-10 pt-lg-15 pb-lg-15">
	   <div className="container">
	      <div className="row align-items-center justify-content-between">
	         <div className="col-12 col-md-7 mb-8 mb-md-0">
	            <h1 className="mb-0">As your digital partner, you should know who you are working with - Meet the team.</h1>
	         </div>
	         <div className="col-12 col-md-5 text-center text-md-right d-print-none">
	            <div className="img-holder">
                  <img src={'/intro-3.svg'} width="305" height="305" alt="" />
                  
	            </div>
	         </div>
	      </div>
	   </div>
	</section>
<div className="container d-print-none">
   <div className="content-box bg-light-cyan mb-8 mb-lg-19">
      <div className="vertical-slider projects-carousel about-carousel">
         <div className="slide">
            <div className="row w-md-100">
               <div className="col-md-6 position-relative d-print-none">
               <div className="bg-stretch bg-img" id="bg-stretch1596537568899212"> </div>
               </div>
               <div className="col-md-6">
                  <div className="block-vc d-flex align-items-center p-6 mx-lg-6">
                     <div className="wrap w-100">
						 <h4 className="mb-4">We are group of digital specialists who have been partnering with organisations across many industries for the past 17 years.</h4>
						 <p>This vast experience across <a href="/government">Government</a>, <a href="/not-for-profit">Not for profit</a>, <a href="/utilities">Utilities</a> and <a href="/education">Education</a> has provided us with valuable insights that we reinvest into each new project we undertake.</p>
						<p>We believe we are always learning, and whilst we may have refined a process that can deliver exceptional results every time, we are always excited in technological advancements if it can help us continually improve. </p>
						<p>We seek to learn intimately about your organisation and its users and understand the success criteria for your project. Our team comprises Business analysts, UX &amp; UI designers, and Front &amp; Backend web developers who have many years of experience using open source technologies such as <a href="/drupal-developers-melbourne/">Drupal</a>, <a href="/wordpress-developers-melbourne/">WordPress</a> and <a href="/joomla-web-development/">Joomla</a>.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<section className="team-section py-10">
   <div className="container">
      <div className="heading-block text-center mb-10 mb-lg-20">
         <h2 className="h1 w-700 mb-3 mb-md-5">Meet the team</h2>
      </div>
      <div className="team-list row">
        {allWordpressPost.edges.map((post, index) => (
         
           <Fade key={index} className="text-center col-12 col-sm-6 col-md-4 col-lg-3 mb-8 mb-md-10" >
           
            <div key={index} className="img-holder rounded-circle d-block position-relative mb-4" style={{height:'146px',width: '146px'}}>
               <strong className="sr-only">This is image link</strong>
               <Img
                     fixed={post.node.acf.bio_image.localFile.childImageSharp.fixed}
                     alt={post.node.acf.bio_image.alt}
                     imgStyle={{  width: '146px',height: '146px' }}
						/>
            </div>
            <span className="name d-block mb-1">{post.node.title}</span>
            <span className="designation d-block">{post.node.acf.position_title}</span>
         </Fade>
        ))}	

      </div>
   </div>
</section>
<Fade className="logo-section bg-light-cyan pt-12 pb-6">
            <div className="container ">
               <div className="heading-block text-center mb-10 mb-lg-12 ">
                  <h2 className="h1 fw-700 mb-3 mb-md-5">Awards and recognitions</h2>
               </div>
               <ul className="logo-list alt-style text-center d-flex flex-wrap align-items-center justify-content-center mx-n3  d-print-none">
				      <li className="px-3 mb-6">
                        <img src={'/govdesign.png'} srcSet={'/govdesign.png 2x'} alt="govdesign" width="70" height="98" />
                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-agda.png'} srcSet={'/logo-agda@2x.png 2x'} alt="agda" width="78" height="30"/>
                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-cssdesign.png'} srcSet={'/logo-cssdesign@2x.png 2x'} alt="CSSDesignAwards" width="130" height="20"/>
                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-award.png'} srcSet={'/logo-award@2x.png 2x'} alt="Awwards" width="75" height="55"/>                   
                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-special.png'} srcSet={'/logo-special@2x.png 2x'} alt="special kudos" width="47" height="56"/>
                  </li>
               </ul>
            </div>
         </Fade>
         <Fade className="logo-section pt-12 pb-6 pt-lg-20 pb-lg-11">
            <div className="container">
               <div className="heading-block text-center mb-10 mb-lg-21 ">
                  <h2 className="h1 fw-700 mb-3 mb-md-5">Clients we work with</h2>
                  <p className="mb-0">A small selection of clients across various industries we have been fortunate enough to partner with.</p>
               </div>
               <ul className="logo-list alt-style text-center d-flex flex-wrap align-items-center justify-content-center mx-n3  d-print-none">
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-telstra.png'} srcSet={'/logo-telstra@2x.png 2x'} alt="telstra" width="104" height="34"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-aus-aca.png'} srcSet={'/logo-aus-aca@2x.png 2x'} alt="Australian Academy of Science" width="103" height="37"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-victoria-small.png'} srcSet={'/logo-victoria-small@2x.png 2x'} alt="Victoria state government" width="78" height="45" />
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-nylex.png'} srcSet={'/logo-nylex@2x.png 2x'} alt="nylex" width="91" height="32" />
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-swin.png'} srcSet={'/logo-swin@2x.png 2x'} alt="swin bur ne" width="86" height="43"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-aus-govt-energy.png'} srcSet={'/logo-aus-govt-energy@2x.png 2x'} alt="australian government | australian renewable energy Agency" width="189" height="57"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-veac.png'} srcSet={'/logo-veac@2x.png 2x'} alt="veac" width="97" height="33"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-monash-university.png'} srcSet={'/logo-monash-university@2x.png 2x'} alt="monash university" width="139" height="42"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-moose.png'} srcSet={'/logo-moose@2x.png 2x'} alt="moose" width="92" height="58"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-transurban.png'} srcSet={'/logo-transurban@2x.png 2x'} alt="transurban" width="136" height="42"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-infoxchange.png'} srcSet={'/logo-infoxchange@2x.png 2x'} alt="infoxchange" width="134" height="28"/>
                     
                  </li>
                  <li className="px-3 mb-6 mb-lg-16">
                     
                        <img src={'/logo-sro.png'} srcSet={'/logo-sro@2x.png 2x'} alt="state revenue office victoria" width="94" height="69"/>
                     
                  </li>
               </ul>
            </div>
         </Fade>
         <Fade className="bg-decor-section has-bottom decor-bg-light-green">
            <div className="container py-8 py-lg-18">
               <div className="heading-block text-center mb-10 mb-lg-15 ">
                  <h2 className="h1 fw-700 mb-3 mb-md-5">Panels</h2>
                  <p className="mb-0">Webplace are members of the following ICT and procurement panels within government:</p>
               </div>
               <ul className="logo-list text-center d-flex flex-wrap align-items-center justify-content-center mx-n3 mb-8 mb-lg-10  d-print-none">
                  <li className="px-3 mb-6">
                        <img src={'/logo-victoria.png'} srcSet={'/logo-victoria@2x.png 2x'} alt="victoria state goverment" width="101" height="57"/>
                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-tasmania.png'} srcSet={'/logo-tasmania@2x.png 2x'} alt="tasmania explore the possibilities" width="149" height="54"/>
                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-nsw.png'} srcSet={'/logo-nsw@2x.png 2x'} alt="NSW goverment" width="58" height="62"/>

                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-australia.png'} srcSet={'/logo-australia@2x.png 2x'} alt="Government of south australia" width="172" height="48"/>
                  </li>
                  <li className="px-3 mb-6">
                        <img src={'/logo-aus-govt.png'} srcSet={'/logo-aus-govt@2x.png 2x'} alt="Australian goverment" width="138" height="74"/>
                  </li>
				   <li className="px-3 mb-6">
                     
                        <img src={'/dta_logo_grayscale.svg'} alt="DTA" width="87" height="56" />
                     
                  </li>
               </ul>
               <LetsWorkTogether />
            </div>
         </Fade>



<div className="popup-holder">
   { /*allWordpressPost.edges.map((post, index) => (
   	<div id={"team" + index} key={index} className="lightbox-demo bg-white">
   		<div className="img-holder rounded-circle mb-4 mb-md-6">
         
         <Img
							fixed={post.node.acf.bio_image.localFile.childImageSharp.fixed}
                     alt={post.node.acf.bio_image.alt}
                     width="360"
                     height="360"
                     imgStyle={{ position: 'relative' }}
						/>
	      </div>
	      <div className="name d-block mb-1">{post.node.title}</div>
	      <div className="designation d-block mb-4 mb-md-6">{post.node.acf.position_title}</div>
	      <hr />
	      <div className="text">
	         <p className="mb-4 mb-md-5" dangerouslySetInnerHTML={{ __html: post.node.acf.bio }}/>
	      </div>
	      
	   </div>
   ))
   */ }	
</div>
	
	</Layout>;

export default aboutTemplate;
export const pageQuery = graphql`
	query($catSlug: String!, $skip: Int!, $limit: Int!) {
		allWordpressPost(
		filter: { categories: { elemMatch: {slug: {eq: $catSlug } } } }
		skip: $skip
      limit: $limit
      sort: {fields: menu_order, order: ASC}
		) {
			 edges {
		      node {
		      	title
		        content
		        acf {
		          first_name
		          position_title
		          bio
		          skillset
		          qualifications
		          fun_facts
		          linked_in_url
		          intro
		          bio_image {
                  localFile {
                   childImageSharp {
                     fixed( width: 360, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                        width
                        height
                        src
                        srcSet
                     }
                   }
                 }
                }
		        }
		        excerpt
		      }
		    }
		}
		file(relativePath: { eq: "office.jpg"}) {
			childImageSharp {
				fixed (width: 600, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
               ...GatsbyImageSharpFixed
               width
               height
               src
               srcSet
             }
			}
		}
	}
`
